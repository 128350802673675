html,
body {
  width: 100%;
  height: 100%;
}

.bg-positive {
  background-color: #02b5ad;
}

.bg-positive:hover {
  background-color: #02a8a1;
}

#root {
  height: 100%;
}

.modal {
  transition: opacity 0.25s ease;
}

body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fna-color {
  background-color: #3c67de;
  color: #fff;
}

.fna-color-secoundary {
  background-color: #cbd300;
  color: #fff;
}

.fna-color:hover {
  background-color: #5683ff;
}

.timer {
  border-radius: 50%;
  width: 30px;
  height: 20px;
  padding: 2px 5px;
}

.text-fna {
  color: #3c67de;
  color: rgba(86, 131, 255, 1);
}

.stroke-fna {
  stroke: #3c67de;
}

.close-frame {
  max-width: 16px;
}

.video-circle {
  z-index: 2;
  width: 200;
  height: 200;
}


@media screen and (min-width:1024px) {
  .close-frame {
    max-width: 18px;
  }

  .video-circle {
    width: 250;
    height: 250;
  }
}

@media screen and (min-width:1280px) {
  .close-frame {
    max-width: 20px;
  }

  .video-circle {
    width: 325;
    height: 325;
  }
}